import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'

const Vue = createApp(App)
Vue.use(router)
Vue.use(Quasar, quasarUserOptions)
Vue.mount('#app')
