import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Projects',
    component: () => import('../views/Projects.vue'),
  },
  {
    path: '/gencon/:id',
    name: 'Gencon',
    component: () => import('../views/Gencon.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue'),
    meta: { layoutDisabled: true }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: () => import('../views/AdminPage.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
