<template>
  <div class="">
    <router-view v-if="$route.meta.layoutDisabled"/>
    <q-layout view="hHh Lpr lff" class="shadow-2" v-if="!$route.meta.layoutDisabled">
      <q-header elevated>
        <q-toolbar>
          <q-btn flat @click="drawer = !drawer" round dense icon="menu" />
          <q-toolbar-title>GENCON</q-toolbar-title>
        </q-toolbar>
      </q-header>

      <q-drawer
        v-model="drawer"
        show-if-above

        :mini="miniState"
        @mouseover="miniState = false"
        @mouseout="miniState = true"

        :width="200"
        :breakpoint="500"
        bordered
        class="bg-grey-3"
      >
        <q-scroll-area class="fit">
          <q-list padding>
            <q-item clickable v-ripple to="/" exact>
              <q-item-section avatar>
                <q-icon name="list_alt" />
              </q-item-section>

              <q-item-section>
                Projects
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple to="/settings" exact>
              <q-item-section avatar>
                <q-icon name="settings" />
              </q-item-section>

              <q-item-section>
                Settings
              </q-item-section>
            </q-item>
            <q-item clickable v-ripple to="/Admin" exact>
              <q-item-section avatar>
                <q-icon name="admin_panel_settings" />
              </q-item-section>

              <q-item-section>
                Admin
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>
      </q-drawer>
      <q-page-container>
        <q-page>
          <router-view/>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import UtilMixin from './UtilMixin'

export default {
  name: 'Home',
  components: {
  },
  mixins: [
    UtilMixin
  ],
  data() {
    return {
      drawer: false,
      miniState: true,
      layout: true,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token')
      localStorage.removeItem('username')
      localStorage.removeItem('roles')
      this.goto('/login')
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .6s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.swal2-container {
  z-index: 10000!important;
}
</style>